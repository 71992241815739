import React, { useContext, useState } from 'react'
import { AuthContext } from '../../context/AuthContext';

export default function Login() {
    const { login } = useContext(AuthContext);

    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');

    const handleLogin = () => {
        login(email, password)
    }

    return (
        <div>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <input type="text" value={password} onChange={(e) => setPassword(e.target.value)} />
            <button onClick={handleLogin}>Iniciar sesión</button>
        </div>
    )
}
