import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { createContext, useEffect, useState } from "react";
import { auth } from "../services/firebase/firebase";
import Cookies from "js-cookie";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [ user, setUser ] = useState(undefined);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
            } else {
                setUser(null);
            }
        })
    })

    const handleLogin = async (email, password) => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
            const user = auth.currentUser;

            if (user) {
                const idToken = await user.getIdToken();

                Cookies.set('authToken', idToken, { domain: '.yugacore.com' });
                console.log('Gucci')
            }
        } catch (error) {
            console.log('error al iniciar sesión:', error)
        }
    };

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            Cookies.remove('authToken', { domain: '.yugacore.com' });
        } catch (error) {
            console.log('error al cerrar sesión', error)
        }
    }

    const value = {
        user,
        login: handleLogin,
        logout: handleSignOut,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}