import React from 'react'
import { Link } from 'react-router-dom'

export default function Home() {
  return (
    <div>
      <Link to="https://accounts.yugacore.com/v1/account-chooser/identifier?origin=Yüga&continue=https%3A%2F%2Faccounts.yugacore.com">
        Iniciar sesión
      </Link>
    </div>
  )
}
